<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="企业成员" name="first">
        <Tabs1 v-if="activeName == 'first'"></Tabs1>
      </el-tab-pane>
      <el-tab-pane label="部门设置" name="second">
        <Tabs2 v-if="activeName == 'second'"></Tabs2>
      </el-tab-pane>
      <el-tab-pane label="权限管理" name="three">
        <Tabs3 v-if="activeName == 'three'"></Tabs3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tabs1 from "./enterpriseMember1.vue";
import Tabs2 from "./sectorSetup/sectorSetup.vue";
import Tabs3 from "./AdministratorList/AdministratorList.vue";
export default {
  data() {
    return {
      activeName: "first", //tabs页
    };
  },
  components: {
    Tabs1,
    Tabs2,
    Tabs3,
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
